import React from 'react';
import CookieConsent from 'react-cookie-consent';

const cookieConsent = () => {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
      >
        FYI - this site uses Google Analytics.
      </CookieConsent>
    </>
  );
};

export default cookieConsent;
